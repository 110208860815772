enum QueryParams {
  HAS_BACK_STACK = "hasBackStack",
  PROJECT_ID = "projectId",
  RUN_ID = "runId",
  TAB = "tab",
  SECTION = "section",
  TYPE = "type",
  SUBTYPE = "subtype",
}

export default QueryParams;
